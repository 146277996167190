import z from "zod"

import {
    AgoraAppId,
    AgoraChannelName,
    AgoraToken,
    AgoraUserId,
    AgoraVideoId,
    DatabaseNotificationId,
    LiveEventId,
    MessageRoomId,
    NotificationId,
    NotificationSubscriptionId,
    PaymentId,
    PointSystemTransactionId,
    PostitId,
    ProfileChallengeId,
    ProfileChallengeOverviewId,
    ProfileRewardId,
    ReportId,
    ResourceHost,
    ResourceId,
    TierId,
    Url,
    UserCommentId,
    UserId,
    UserMessageId,
    UserName,
    UserProfileId,
    VideoId,
    VoteId,
} from "./branded-types"

export const Pagination = z
    .intersection(
        z
            .discriminatedUnion("type", [
                z.object({
                    type: z.literal("Seek"),
                    id: z.string().optional(),
                }),
                z.object({
                    type: z.literal("Index"),
                    index: z.number().optional(),
                }),
            ])
            .default({ type: "Index" }),
        z.object({
            direction: z
                .union([z.literal("After"), z.literal("Before")])
                .default("After"),
            limit: z.number().default(50),
        }),
    )
    .default({ type: "Index" })

export type Pagination = z.infer<typeof Pagination>

export const ApiError = z.object({
    // localizable code like LiveStream.NotFound
    // <context.type>
    code: z.string(),
    // default localization message if not existent
    message: z.string(),
})
export type ApiError = z.infer<typeof ApiError>

export const LiveEventState = z.union([
    z.literal("Planned"),
    z.literal("Running"),
    z.literal("Finished"),
])
export type LiveEventState = z.infer<typeof LiveEventState>

export const PlannedLiveEventResponse = z.object({
    id: LiveEventId,
})
export type PlannedLiveEventResponse = z.infer<typeof PlannedLiveEventResponse>

export const LiveEvent = z.object({
    id: LiveEventId,
    title: z.string(),
    description: z.string().optional(),
    start: z.string(),
    creatorId: UserProfileId,
    state: LiveEventState,
    // timestamp UTC when the live event was updated
    lastChangedAt: z.string(),
    // timestamp UTC when the live event was created
    createdAt: z.string(),
})
export type LiveEvent = z.infer<typeof LiveEvent>

export const LiveEventEnded = z.object({
    id: LiveEventId,
    started: z.string(),
    ended: z.string(),
    viewCount: z.number(),
})
export type LiveEventEnded = z.infer<typeof LiveEventEnded>

export const LiveEventStatistic = z.object({
    id: LiveEventId,
    creatorId: UserProfileId,
    state: LiveEventState,
    viewCount: z.object({
        current: z.number(),
        total: z.number(),
    }),
    // timestamp UTC when the live event was actually started (not created)
    startedAt: z.string(),
    // timestamp UTF of current duration of the live event
    duration: z.string(),
})
export type LiveEventStatistic = z.infer<typeof LiveEventStatistic>

export const AgoraVideo = z.object({
    id: AgoraVideoId,
    eventId: LiveEventId,
    userId: AgoraUserId,
    appId: AgoraAppId,
    channelName: AgoraChannelName,
    // The API generates a token that encapsulates the roles of the Agora user,
    // enabling them to successfully join a channel.
    token: AgoraToken,
    joinAs: z.union([z.literal("Audience"), z.literal("Host")]),
})
export type AgoraVideo = z.infer<typeof AgoraVideo>

export const User = z.object({
    id: UserId,
    // only used for the login
    username: UserName,
    isOnline: z.boolean(),
})
export type User = z.infer<typeof User>

export const UserPaymentInfo = z.object({
    id: UserId,
    createdAt: z.string(),
    address: z.string(),
    name: z.string(),
    taxId: z.string(),
    isSalesTaxLiable: z.boolean(),
})
export type UserPaymentInfo = z.infer<typeof UserPaymentInfo>

export const UserProfile = z.object({
    id: UserProfileId,
    userId: UserId,
    isLive: z.boolean(),
    isDefaultImage: z.boolean(),
    imageUrl: Url,
    // The user can modify the display name (in their profile) and
    // it is not limited to a single instance.
    displayName: z.string().optional(),
    // A distinctive name selected by the user during the registration process.
    profileName: z.string(),
    description: z.string().optional(),
    location: z.string().optional(),
    engagementScore: z.number(),
    createdAt: z.string(),
    lastChangedAt: z.string(),
    links: z.string().array(),
})
export type UserProfile = z.infer<typeof UserProfile>

export const Currency = z.union([z.literal("EUR"), z.literal("USD")])
export type Currency = z.infer<typeof Currency>

export const NoMonetization = z.object({ type: z.literal("None") })
export type NoMonetization = z.infer<typeof NoMonetization>

export const Price = z.object({
    amount: z
        .number()
        .min(0, { message: "Amount must be a non-negative number." }),
    currency: Currency.default("EUR"),
})
export type Price = z.infer<typeof Price>

export const SubscriptionMonetization = z
    .object({
        type: z.literal("SubscriptionOnly"),
    })
    .merge(Price)
export type SubscriptionMonetization = z.infer<typeof SubscriptionMonetization>

export const MonetizationOption = z
    .union([NoMonetization, SubscriptionMonetization])
    .default({ type: "None" })
export type MonetizationOption = z.infer<typeof MonetizationOption>

export const Video = z.object({
    id: VideoId,
    title: z.string(),
    description: z.string().optional(),
    creatorId: UserProfileId,
    mimeType: z.string(),
    url: Url.optional(),
    previewImageUrl: Url.optional(),
    // timestamp UTC when the video was updated
    lastChangedAt: z.string(),
    // timestamp UTC when the video was created
    createdAt: z.string(),
    monetization: MonetizationOption,
})
export type Video = z.infer<typeof Video>

export const PayableVideo = Video.extend({
    paymentRequired: z.boolean(),
})
export type PayableVideo = z.infer<typeof PayableVideo>

export const CreateVideo = z.object({
    profileId: UserProfileId,
    title: z.string(),
    description: z.string().optional(),
    monetization: MonetizationOption,
})
export type CreateVideo = z.infer<typeof CreateVideo>

export const CreateVideoResponse = z.object({
    data: Video,
    uploads: z.object({
        video: Url,
        previewImage: Url,
    }),
})
export type CreateVideoResponse = z.infer<typeof CreateVideoResponse>

export const UpdateVideo = z.union([
    z.object({
        title: z.string(),
        description: z.string().optional(),
    }),
    z.undefined(),
])
export type UpdateVideo = z.infer<typeof UpdateVideo>

export const UpdateVideoResponse = CreateVideoResponse
export type UpdateVideoResponse = z.infer<typeof UpdateVideoResponse>

export const UpdateUserProfile = z.object({
    displayName: z.string().optional(),
    profileName: z.string().optional(),
    description: z.string().optional(),
    location: z.string().optional(),
    links: z.string().array().optional(),
})
export type UpdateUserProfile = z.infer<typeof UpdateUserProfile>

export const LocationSuggestion = z.string()
export type LocationSuggestion = z.infer<typeof LocationSuggestion>

export const ContentFilters = z.union([z.literal("*"), z.string()])

export const ContentShareLiterals = z.union([
    z.literal("UserProfile"),
    z.literal("User"),
    z.literal("Video"),
    z.literal("LiveEvent"),
    z.literal("Asset"),
])
export type ContentShareLiterals = z.infer<typeof ContentShareLiterals>

// FIXME remove redundant literal definition - ContentShareLiterals
export const ContentShareType = z.union([
    z.object({
        contentType: z.literal("UserProfile"),
        contentFilter: ContentFilters,
    }),
    z.object({
        contentType: z.literal("User"),
        contentFilter: ContentFilters,
    }),
    z.object({
        contentType: z.literal("Video"),
        contentFilter: ContentFilters,
    }),
    z.object({
        contentType: z.literal("LiveEvent"),
        contentFilter: ContentFilters,
    }),
    z.object({
        contentType: z.literal("Asset"),
        contentFilter: ContentFilters,
    }),
    z.object({
        contentType: z.literal("Postit"),
        contentFilter: ContentFilters,
    }),
])
export type ContentShareType = z.infer<typeof ContentShareType>

export const VotedResourceContentTypes = z.union([
    z.literal("UserProfile"),
    z.literal("Video"),
    z.literal("LiveEvent"),
    z.literal("Postit"),
])
export type VotedResourceContentTypes = z.infer<
    typeof VotedResourceContentTypes
>

// FIXME remove redundant literal definition - VotedResourceContentTypes
export const VotedResourceRef = z.union([
    z.object({
        contentType: z.literal("UserProfile"),
        contentId: UserProfileId,
    }),
    z.object({
        contentType: z.literal("Video"),
        contentId: VideoId,
    }),
    z.object({
        contentType: z.literal("LiveEvent"),
        contentId: LiveEventId,
    }),
    z.object({
        contentType: z.literal("Postit"),
        contentId: PostitId,
    }),
])
export type VotedResourceRef = z.infer<typeof VotedResourceRef>

export const VoteContentIddResourceRefs = z.union([
    UserProfileId,
    VideoId,
    LiveEventId,
    PostitId,
])
export type VoteContentIddResourceRefs = z.infer<
    typeof VoteContentIddResourceRefs
>

export const CreatorRef = z.union([
    z.object({
        type: z.literal("User"),
        id: UserId,
    }),
    z.object({
        type: z.literal("UserProfile"),
        id: UserProfileId,
    }),
])
export type CreatorRef = z.infer<typeof CreatorRef>

export const VoteType = z.union([z.literal("Like"), z.literal("None")])
export type VoteType = z.infer<typeof VoteType>

export const Vote = z.object({
    id: VoteId,
    type: VoteType,
    voter: CreatorRef,
    resource: VotedResourceRef,
})
export type Vote = z.infer<typeof Vote>

export const AccountType = z.union([
    z.literal("Anonymous"),
    z.literal("User"),
    z.literal("Trial"),
    z.literal("Guest"),
])
export type AccountType = z.infer<typeof AccountType>

export const CommentedResourceRef = z.union([
    z.object({
        contentType: z.literal("Video"),
        contentId: VideoId,
    }),
    z.object({
        contentType: z.literal("LiveEvent"),
        contentId: LiveEventId,
    }),
    z.object({
        contentType: z.literal("Postit"),
        contentId: PostitId,
    }),
])
export type CommentedResourceRef = z.infer<typeof CommentedResourceRef>

export const CommentContentIddResourceRefs = z.union([
    VideoId,
    LiveEventId,
    PostitId,
])
export type CommentContentIddResourceRefs = z.infer<
    typeof CommentContentIddResourceRefs
>

export const UserComment = z.object({
    id: UserCommentId,
    creatorId: UserProfileId,
    resourceOwnerId: UserProfileId,
    text: z.string(),
    resource: CommentedResourceRef,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type UserComment = z.infer<typeof UserComment>

export const MessageRecipientRef = z.object({
    contentType: z.literal("UserProfile"),
    contentId: UserProfileId,
})
export type MessageRecipientRef = z.infer<typeof MessageRecipientRef>

export const UserMessage = z.object({
    id: UserMessageId,
    senderId: UserProfileId,
    text: z.string(),
    isSeen: z.boolean().default(false),
    recipient: MessageRecipientRef,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type UserMessage = z.infer<typeof UserMessage>

export const PaymentResourceRef = z.union([
    z.object({
        contentType: z.literal("Video"),
        contentId: VideoId,
    }),
    z.object({
        contentType: z.literal("LiveEvent"),
        contentId: LiveEventId,
    }),
    z.object({
        contentType: z.literal("Postit"),
        contentId: PostitId,
    }),
])
export type PaymentResourceRef = z.infer<typeof PaymentResourceRef>

export const PaymentState = z.union([
    z.literal("Pending"),
    z.literal("Succeeded"),
    z.literal("Cancelled"),
    z.literal("Failed"),
])
export type PaymentState = z.infer<typeof PaymentState>

export const VideoPayment = z.object({
    id: PaymentId,
    customerId: UserProfileId,
    state: PaymentState,
    price: Price,
    contentRef: z.object({
        contentType: z.literal("Video"),
        contentId: VideoId,
    }),
    userId: UserId,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type VideoPayment = z.infer<typeof VideoPayment>

export const LiveEventPayment = z.object({
    id: PaymentId,
    customerId: UserProfileId,
    state: PaymentState,
    price: Price,
    contentRef: z.object({
        contentType: z.literal("LiveEvent"),
        contentId: LiveEventId,
    }),
    userId: UserId,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type LiveEventPayment = z.infer<typeof LiveEventPayment>

export const PostitPayment = z.object({
    id: PaymentId,
    customerId: UserProfileId,
    state: PaymentState,
    price: Price,
    contentRef: z.object({
        contentType: z.literal("Postit"),
        contentId: PostitId,
    }),
    userId: UserId,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type PostitPayment = z.infer<typeof PostitPayment>

export const Payment = z.object({
    id: PaymentId,
    customerId: UserProfileId,
    state: PaymentState,
    price: Price,
    contentRef: PaymentResourceRef,
    userId: UserId,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type Payment = z.infer<typeof Payment>

export const NotificationSubscriptionType = z.union([
    z.literal("Vapid"),
    z.literal("Apns"),
    z.literal("Fcm"),
])
export type NotificationSubscriptionType = z.infer<
    typeof NotificationSubscriptionType
>

export const NotificationSubscriptionBase = z.object({
    id: NotificationSubscriptionId,
    userId: UserId,
    profileId: UserProfileId,
})
export type NotificationSubscriptionBase = z.infer<
    typeof NotificationSubscriptionBase
>

export const VapidNotificationSubscription =
    NotificationSubscriptionBase.extend({
        type: z.literal("Vapid"),
        endpoint: z.string(),
    })
export type VapidNotificationSubscription = z.infer<
    typeof VapidNotificationSubscription
>

export const FcmNotificationSubscription = NotificationSubscriptionBase.extend({
    type: z.literal("Fcm"),
    topic: z.string(),
})
export type FcmNotificationSubscription = z.infer<
    typeof FcmNotificationSubscription
>

export const ApnsNotificationSubscription = NotificationSubscriptionBase.extend(
    {
        type: z.literal("Apns"),
    },
)
export type ApnsNotificationSubscription = z.infer<
    typeof ApnsNotificationSubscription
>

export const NotificationSubscription = z.union([
    VapidNotificationSubscription,
    FcmNotificationSubscription,
    ApnsNotificationSubscription,
])
export type NotificationSubscription = z.infer<typeof NotificationSubscription>

export const NotificationTag = z.union([
    z.literal("Like"),
    z.literal("Comment"),
    z.literal("Follow"),
    z.literal("Unfollow"),
    z.literal("Created"),
    z.literal("Started"),
    z.literal("Stopped"),
    z.literal("Subscribe"),
    z.literal("Unsubscribe"),
    z.literal("Paid"),
    z.literal("Bought"),
    z.literal("Message"),
])
export type NotificationTag = z.infer<typeof NotificationTag>

export const NotificationClickActionElement = z.object({
    contentType: z.string(),
    contentId: z.string(),
})
export type NotificationClickActionElement = z.infer<
    typeof NotificationClickActionElement
>

export const NotificationClickActionDocument = z.record(
    NotificationClickActionElement,
)
export type NotificationClickActionDocument = z.infer<
    typeof NotificationClickActionDocument
>

export const PushNotification = z.object({
    title: z.string(),
    message: z.string(),
    clickAction: z.string().optional(),
    icon: z.string().url(),
    tag: NotificationTag.optional(),
    sound: z.string().url().optional(),
})
export type PushNotification = z.infer<typeof PushNotification>

export const ProcessedPushNotification = z.object({
    title: z.string(),
    message: z.string(),
    clickAction: NotificationClickActionDocument.optional(),
    icon: z.string().url(),
    tag: NotificationTag.optional(),
    sound: z.string().url().optional(),
})
export type ProcessedPushNotification = z.infer<
    typeof ProcessedPushNotification
>

export const DatabaseNotificationType = z.union([
    z.literal("NewNotification"),
    z.literal("UserNotification"),
])
export type DatabaseNotificationType = z.infer<typeof DatabaseNotificationType>

export const DatabaseNotification = z.object({
    id: DatabaseNotificationId,
    notificationId: NotificationId,
    pushNotification: ProcessedPushNotification,
    notificationType: DatabaseNotificationType,
    senderId: UserProfileId,
    receiverId: UserProfileId,
    userId: UserId,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type DatabaseNotification = z.infer<typeof DatabaseNotification>

export const Resolution = z.object({
    width: z.number(),
    height: z.number(),
})
export type Resolution = z.infer<typeof Resolution>

export const ResourceRef = z.object({
    resourceHost: ResourceHost,
    resourceId: ResourceId,
})
export type ResourceRef = z.infer<typeof ResourceRef>

export type ResourceUrl = `${ResourceHost}${ResourceId}`

export const ResultIdMapping = z.object({
    key: z.string(),
    value: z.string(),
})
export type ResultIdMapping = z.infer<typeof ResultIdMapping>

const ImageAssetType = z.literal("Image")
const ReservedAssetType = z.literal("Reserved")
export const AssetType = z.union([ImageAssetType, ReservedAssetType])

const BaseAsset = z.object({
    id: ResourceRef,
    userId: UserId,
    creatorId: UserProfileId,
    name: z.string().optional(),
    source: Url,
    // resultIdMapping: ResultIdMapping,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})

export const ImageType = z.union([
    z.literal("Jpeg"),
    z.literal("Png"),
    z.literal("Svg"),
])
export type ImageType = z.infer<typeof ImageType>

export const ImageAsset = BaseAsset.extend({
    type: ImageAssetType,
    resolution: Resolution,
    imageType: ImageType,
})
export type ImageAsset = z.infer<typeof ImageAsset>

const ReservedAsset = BaseAsset.extend({
    type: ReservedAssetType,
})

export const Asset = z.union([ImageAsset, ReservedAsset])
export type Asset = z.infer<typeof Asset>

const BasePost = z.object({
    id: PostitId,
    title: z.string(),
    message: z.string().optional(),
    creatorId: UserProfileId,
    monetization: MonetizationOption,
})

const TextPostSchema = BasePost.extend({
    type: z.literal("Text"),
})

export const TextPost = TextPostSchema.extend({
    id: PostitId,
    createdAt: z.string(),
})
export type TextPost = z.infer<typeof TextPost>

const ImagePostSchema = BasePost.extend({
    type: z.literal("Image"),
    imageUrl: z.union([Url, ResourceRef]),
})

export const ImagePost = ImagePostSchema.extend({
    id: PostitId,
    createdAt: z.string(),
})
export type ImagePost = z.infer<typeof ImagePost>

export const Postit = z.union([TextPost, ImagePost])
export type Postit = z.infer<typeof Postit>

export const PayablePostit = z.union([
    TextPostSchema.extend({
        id: PostitId,
        createdAt: z.string(),
        paymentRequired: z.boolean(),
    }),
    ImagePostSchema.extend({
        id: PostitId,
        createdAt: z.string(),
        paymentRequired: z.boolean(),
    }),
])
export type PayablePostit = z.infer<typeof PayablePostit>

const CreateBasePostit = z.object({
    title: z.string(),
    creatorId: UserProfileId,
    monetization: MonetizationOption,
})

const CreateTextPostit = CreateBasePostit.extend({
    type: z.literal("Text"),
    message: z.string(),
})
export type CreateTextPostit = z.infer<typeof CreateTextPostit>

const CreateImagePostit = CreateBasePostit.extend({
    type: z.literal("Image"),
    message: z.string().optional(),
})
export type CreateImagePostit = z.infer<typeof CreateImagePostit>

export const CreatePostit = z.union([CreateTextPostit, CreateImagePostit])
export type CreatePostit = z.infer<typeof CreatePostit>

export const UpdateTextPostit = z.union([
    z.object({
        title: z.string(),
        message: z.string().optional(),
    }),
    z.object({ title: z.string().optional(), message: z.string() }),
])
export type UpdateTextPostit = z.infer<typeof UpdateTextPostit>

export const UpdateImagePostit = z.union([
    z.object({
        title: z.string(),
        message: z.string().optional(),
    }),
    z.undefined(),
])
export type UpdateImagePostit = z.infer<typeof UpdateImagePostit>

export const UpdatePostit = z.union([UpdateTextPostit, UpdateImagePostit])
export type UpdatePostit = z.infer<typeof UpdatePostit>

export const PostitCreated = z.object({
    id: PostitId,
    title: z.string(),
    message: z.string().optional(),
    creatorId: UserProfileId,
    monetization: MonetizationOption,
})
export type PostitCreated = z.infer<typeof PostitCreated>

export const TextPostitUpdated = z.object({
    type: z.literal("Text"),
    id: PostitId,
    title: z.string(),
    message: z.string(),
    creatorId: UserProfileId,
    monetization: MonetizationOption,
})
export type TextPostitUpdated = z.infer<typeof TextPostitUpdated>

export const ImagePostitUpdated = z.object({
    type: z.literal("Image"),
    id: PostitId,
    title: z.string(),
    message: z.string().optional(),
    creatorId: UserProfileId,
    monetization: MonetizationOption,
})
export type ImagePostitUpdated = z.infer<typeof ImagePostitUpdated>

export const CreatePostitResponse = z.object({
    data: PostitCreated,
    uploads: z.object({
        image: Url,
    }),
})
export type CreatePostitResponse = z.infer<typeof CreatePostitResponse>

export const UpdateTextPostitResponse = z.object({
    data: TextPostitUpdated,
})
export type UpdateTextPostitResponse = z.infer<typeof UpdateTextPostitResponse>

export const UpdateImagePostitResponse = z.object({
    data: ImagePostitUpdated,
    uploads: z.object({
        image: Url,
    }),
})
export type UpdateImagePostitResponse = z.infer<
    typeof UpdateImagePostitResponse
>

export const UpdatePostitResponse = z.union([
    UpdateTextPostitResponse,
    UpdateImagePostitResponse,
])
export type UpdatePostitResponse = z.infer<typeof UpdatePostitResponse>

export type OidcUser = {
    idToken?: string
    accessToken: string
    refreshToken?: string
    expiresAt?: number
    accountType: AccountType
}

export const SingleMessageGroupId = z.object({
    type: z.literal("Single"),
    profileId: UserProfileId,
    partnerId: UserProfileId,
})
export type SingleMessageGroupId = z.infer<typeof SingleMessageGroupId>

export const GroupMessageGroupId = z.object({
    type: z.literal("Group"),
    profileId: UserProfileId,
    partnerId: MessageRoomId,
})
export type GroupMessageGroupId = z.infer<typeof GroupMessageGroupId>

export const MessageGroupId = z.discriminatedUnion("type", [
    SingleMessageGroupId,
    GroupMessageGroupId,
])
export type MessageGroupId = z.infer<typeof MessageGroupId>

export const MessageGroup = z.object({
    id: MessageGroupId,
    profileId: UserProfileId,
    // from the perspective of a user
    newMessageIds: z.array(UserMessageId).default([]),
    firstContacted: z.boolean(),
    partner: MessageRecipientRef,
    createdAt: z.string(),
    lastChangedAt: z.string(),
})
export type MessageGroup = z.infer<typeof MessageGroup>

// Point system

export const PointSystemTransaction = z.object({
    id: PointSystemTransactionId,
    amount: z.number(),
    bookedAt: z.string(),
    reason: z.string(),
})
export type PointSystemTransaction = z.infer<typeof PointSystemTransaction>

export const Progress = z.object({
    done: z.number(),
    required: z.number(),
})
export type Progress = z.infer<typeof Progress>

export const ProfileChallengeTask = z.object({
    challengeRef: ResourceRef,
    challengeId: ProfileChallengeId,
    title: z.string(),
    description: z.string(),
    progress: Progress,
    progressFormat: z.string(),
    logoRef: ResourceRef,
    rewardPoints: z.number(),
    isActive: z.boolean(),
})
export type ProfileChallengeTask = z.infer<typeof ProfileChallengeTask>

export const ProfileChallengeOverview = z.object({
    id: ProfileChallengeOverviewId,
    title: z.string(),
    description: z.string(),
    logoRef: ResourceRef,
    progress: Progress,
    progressFormat: z.string(),
    rewardPoints: z.number(),
})
export type ProfileChallengeOverview = z.infer<typeof ProfileChallengeOverview>

export const ProfileChallenge = z.object({
    id: ResourceRef,
    logoRef: ResourceRef,
    title: z.string(),
    description: z.string(),
    tasks: z.array(ProfileChallengeTask),
})
export type ProfileChallenge = z.infer<typeof ProfileChallenge>

export const ProfileChallenges = z.object({
    firstSteps: z.array(ProfileChallengeTask),
    followupChallenges: z.array(ProfileChallengeTask),
})
export type ProfileChallenges = z.infer<typeof ProfileChallenges>

export const TierType = z.union([
    z.literal("Basic"),
    z.literal("Bronze"),
    z.literal("Silver"),
    z.literal("Gold"),
    z.literal("Platinum"),
])
export type TierType = z.infer<typeof TierType>

export const Benefit = z.object({
    title: z.string(),
    description: z.string(),
    logoRef: ResourceRef,
})
export type Benefit = z.infer<typeof Benefit>

export const TierBenefits = z.object({
    title: z.string(),
    description: z.string(),
    benefits: z.array(Benefit),
})
export type TierBenefits = z.infer<typeof TierBenefits>

export const BulletPoint = z.object({
    lead: z.string(),
    followUp: z.string(),
})
export type BulletPoint = z.infer<typeof BulletPoint>

export const Tier = z.object({
    id: TierId,
    type: TierType,
    logoRef: ResourceRef,
    title: z.string(),
    description: z.string(),
    tierBenefits: TierBenefits,
    bulletPoints: z.array(BulletPoint),
    levelUpHint: z.string(),
    threshold: z.number(),
})
export type Tier = z.infer<typeof Tier>

export const UserProfileStatistic = z.object({
    id: UserProfileId,
    count: z.object({
        followers: z.number(),
        following: z.number(),
        posts: z.number(),
    }),

    currentTier: Tier,
    nextTier: Tier, // ? This matches the `currentTier` value if there is no `nextTier`
    nextTierProgressFormat: z.string(),
    nextTierRemainingFormat: z.string(),
    points: z.number(),
})
export type UserProfileStatistic = z.infer<typeof UserProfileStatistic>

// Reward system messaging
export const TierEventType = z.union([
    z.literal("LevelUp"),
    z.literal("LevelDown"),
])
export type TierEventType = z.infer<typeof TierEventType>

export const TierMessage = z.object({
    type: z.literal("tier"),
    eventType: TierEventType,
    title: z.string(),
    description: z.string(),
    icon: ResourceRef,
})
export type TierMessage = z.infer<typeof TierMessage>

export const ChallengeMessage = z.object({
    type: z.literal("challenge"),
    challengeId: ProfileChallengeId,
    title: z.string(),
    description: z.string(),
    icon: ResourceRef,
    progress: Progress,
    points: z.number(),
})
export type ChallengeMessage = z.infer<typeof ChallengeMessage>

export const ChallengeGroupMessage = z.object({
    type: z.literal("challengeGroup"),
    groupType: z.string(), //? FirstSteps will be one of values
    title: z.string(),
    description: z.string(),
    icon: ResourceRef,
    points: z.number(), //? if groupType is FirstSteps - will be 0
    badgeName: z.string(), //? if groupType is First Steps - will be "",
})
export type ChallengeGroupMessage = z.infer<typeof ChallengeGroupMessage>

export const PointSystemTaskCompleteMessage = z.union([
    TierMessage,
    ChallengeGroupMessage,
    ChallengeMessage,
])
export type PointSystemTaskCompleteMessage = z.infer<
    typeof PointSystemTaskCompleteMessage
>

export const PointSystemMessage = z.union([
    z.literal("tier"),
    z.literal("challenge"),
    z.literal("challengeGroup"),
])
export type PointSystemMessage = z.infer<typeof PointSystemMessage>

export const ContentChangedMessage = z.object({
    type: z.literal("ContentChanged"),
    content: z.union([
        z.literal("Chat"),
        z.literal("PushNotification"),
        z.literal("Challenge"),
        z.literal("Profile"),
        z.literal("ProfileStatistic"),
        z.literal("User"),
    ]),
})
export type ContentChangedMessage = z.infer<typeof ContentChangedMessage>

export const ProfileReward = z.object({
    id: ProfileRewardId,
    points: z.number(),
    title: z.string(),
    createdAt: z.string(),
})
export type ProfileReward = z.infer<typeof ProfileReward>

export const ProfileContentRef = z.object({
    contentType: z.literal("Profile"),
    contentId: UserProfileId,
})
export type ProfileContentRef = z.infer<typeof ProfileContentRef>

export const VideoContentRef = z.object({
    contentType: z.literal("Video"),
    contentId: VideoId,
})
export type VideoContentRef = z.infer<typeof VideoContentRef>

export const PostitContentRef = z.object({
    contentType: z.literal("Postit"),
    contentId: PostitId,
})
export type PostitContentRef = z.infer<typeof PostitContentRef>

export const ContentRef = z.union([
    VideoContentRef,
    PostitContentRef,
    ProfileContentRef,
])
export type ContentRef = z.infer<typeof ContentRef>

export const ContentReportIssue = z.union([
    z.literal("Spam"),
    z.literal("Harassment"),
    z.literal("MissInformation"),
    z.literal("Offensive"),
    z.literal("ArtisticNudity"),
    z.literal("PlainNudity"),
    z.literal("Violence"),
    z.literal("Other"),
])
export type ContentReportIssue = z.infer<typeof ContentReportIssue>

export const UserReportIssue = z.union([
    z.literal("Spam"),
    z.literal("Behaviour"),
    z.literal("Harassment"),
    z.literal("Messages"),
    z.literal("Profile"),
    z.literal("Other"),
])
export type UserReportIssue = z.infer<typeof UserReportIssue>

export const UserReportSubIssue = z.union([
    z.literal("Behaviour_Comments"),
    z.literal("Behaviour_Provoking"),
    z.literal("Behaviour_Gestures"),
    z.literal("Behaviour_Language"),
    z.literal("Harassment_Threatening"),
    z.literal("Harassment_DiscriminationOrInsult"),
    z.literal("Harassment_MobbingOrBullying"),
    z.literal("Harassment_UnwantedRepeatedContact"),
    z.literal("Profile_StolenOrFake"),
    z.literal("Profile_ScamOrFraud"),
    z.literal("Profile_FairUse"),
    z.literal("Profile_Inconsistent"),
    z.literal("Messages_SexualOrOffensive"),
    z.literal("Messages_SpamOrRepeatedContact"),
    z.literal("Messages_IntrusiveOrInappropriate"),
    z.literal("Messages_ThreatOrInsult"),
    z.literal("Spam_BroadbandMessages"),
    z.literal("Spam_LinkOrAdvertisement"),
    z.literal("Spam_UnwantedRepeatedDuplication"),
    z.literal("Spam_MissleadingOrManipulative"),
    z.literal("Other"),
])
export type UserReportSubIssue = z.infer<typeof UserReportSubIssue>

export const CreateUserReport = z.object({
    type: z.literal("User"),
    customDetails: z.string(),
    resource: ContentRef,
    issue: UserReportIssue,
    subIssue: UserReportSubIssue,
    resourceOwnerId: UserProfileId,
})
export type CreateUserReport = z.infer<typeof CreateUserReport>

export const CreateContentReport = z.object({
    type: z.literal("Content"),
    customDetails: z.string(),
    resource: ContentRef,
    issue: ContentReportIssue,
    resourceOwnerId: UserProfileId,
})
export type CreateContentReport = z.infer<typeof CreateContentReport>

export const CreateReport = z.union([CreateUserReport, CreateContentReport])
export type CreateReport = z.infer<typeof CreateReport>

export const ContentReport = z.object({
    id: ReportId,
    type: z.literal("Content"),
    content: ContentRef,
    createdAt: z.string(),
    customDetails: z.string(),
    issue: ContentReportIssue,
    reportedProfile: UserProfileId,
    reportingProfile: UserProfileId,
})
export type ContentReport = z.infer<typeof ContentReport>

export const UserReport = z.object({
    id: ReportId,
    type: z.literal("User"),
    content: ContentRef,
    createdAt: z.string(),
    customDetails: z.string(),
    issue: UserReportIssue,
    subIssue: UserReportSubIssue,
    reportedProfile: UserProfileId,
    reportingProfile: UserProfileId,
})
export type UserReport = z.infer<typeof UserReport>

export const Report = z.union([ContentReport, UserReport])

import { faEye } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { CSSProperties, FC } from "react"

import { Url } from "../../../api/branded-types"
import { Avatar } from "../../../components/avatars/avatar"
import { AvatarLoading } from "../../../components/avatars/avatar-loading"
import { stopPropagation } from "../../../utils/fp"
import { isFoldNeeded, truncateText } from "../../../utils/text"

import Skeleton from "react-loading-skeleton"
import { vars } from "theme/variables.css"
import * as styles from "./live-event-search-result-tile.css"

export type LiveEventSearchResultTileModel = {
    style?: CSSProperties | undefined
    className?: string
    stateText: string
    title: string
    avatar: {
        name: string
        image: Url
        isLive: boolean
        viewCount: number
        onClicked: () => void
    }
    onClicked: () => void
}

export const LiveEventSearchResultTile: FC<LiveEventSearchResultTileModel> = ({
    style,
    className = "",
    stateText,
    title,
    avatar,
    onClicked,
}) => (
    <div
        style={{
            backgroundImage: "url(/icons/custom/live-now-area-tile-bg.svg)",
            ...style,
        }}
        className={classNames(styles.tile, className)}
        onClick={onClicked}
    >
        <div
            className={styles.stats}
            style={{ fontSize: vars.font.size.regular }}
        >
            <div className={styles.playState}>{stateText}</div>

            <div className={styles.viewerCount}>
                <FontAwesomeIcon
                    className={styles.viewerCountIcon}
                    icon={faEye}
                    color="white"
                />
                <p style={{ fontSize: vars.font.size.s }}>{avatar.viewCount}</p>
            </div>
        </div>

        <div className={styles.overlay} />

        <p
            className={styles.title}
            style={{ fontSize: vars.font.size.regular }}
        >
            {pipe(
                title,
                O.fromPredicate(isFoldNeeded(40)),
                O.map(truncateText(40)),
                O.map(text => `${text}...`),
                O.getOrElse(() => title),
            )}
        </p>

        <div
            className={styles.avatarWrapper}
            onClick={flow(
                e => stopPropagation(e),
                () => (avatar.onClicked ? avatar.onClicked() : {}),
            )}
        >
            <Avatar
                className={styles.avatar}
                size={36}
                src={avatar.image}
                styleState={avatar.isLive ? "isLive" : "None"}
            />

            <p
                className={styles.userName}
                style={{ fontSize: vars.font.size.xs }}
            >
                {avatar.name}
            </p>
        </div>
    </div>
)

export const LiveEventSearchResultLoadingTile: FC = () => (
    <div className={styles.tile}>
        <Skeleton
            width="100%"
            height="100%"
            containerClassName="flex-fit"
            borderRadius={18}
            style={{ paddingLeft: 2 }}
        />
        <div className={styles.avatarWrapper}>
            <AvatarLoading size={36} baseColor="#3C375C" />
            <Skeleton
                width="100%"
                containerClassName="flex-fit"
                height={18}
                borderRadius={24}
            />
        </div>
    </div>
)

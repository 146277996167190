import { faAngleRight, faImages } from "@fortawesome/pro-light-svg-icons"
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonInput, IonTextarea, IonToast } from "@ionic/react"
import classNames from "classnames"
import * as O from "fp-ts/Option"
import { flow, pipe } from "fp-ts/function"
import { FC, useEffect, useMemo, useRef, useState } from "react"

import { NavigationBackButton } from "components/buttons/navigation-back-button"
import { VisibilityAdt } from "data-flow/common"
import { useMyUserProfileId } from "hooks/use-my-user-profiles"
import { getEventDetailValue } from "utils/fp"

import { getIsAuthorizedAccount } from "api/api-utils"
import { PostitId } from "api/branded-types"
import { Content } from "common/content"
import { Footer } from "common/footer"
import { Page } from "common/page"
import { Toolbar } from "common/toolbar"
import { SubscriptionPickerModal } from "features/upload/subscription-picker-modal"
import * as A from "fp-ts/Array"
import { useMe } from "hooks/use-me"
import { LocalStorage } from "local-storage"
import { useNavigate, useParams } from "react-router-dom"
import { vars } from "theme/variables.css"
import { fromEvent } from "utils/file-selector"
import * as styles from "./edit-image-post-page.css"
import { useEditImage } from "./hooks/use-edit-image"
import { useEditImagePostStore } from "./store/edit-image-post-store"

const MAX_TITLE_LENGTH = 120
const MAX_CONTENT_LENGTH = 500

export const EditImagePostPage: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const postitId = PostitId.parse(id)

    const meQuery = useMe()

    const accountType = meQuery.isSuccess
        ? meQuery.data.accountType
        : pipe(
              LocalStorage.getAccountType(),
              O.getOrElseW(() => undefined),
          )

    const isAuthorizedAccount = useMemo(
        () => getIsAuthorizedAccount(accountType),
        [accountType],
    )
    const { data: profileId } = useMyUserProfileId(isAuthorizedAccount)

    const [paymentPickerVisibility, setPaymentPickerVisibility] = useState(
        VisibilityAdt.of.Invisible({}),
    )

    const inputFile = useRef<HTMLInputElement>(null)

    const {
        isFileInvalid,
        isUploadButtonDisabled,
        uploadFailed,
        updateComplete,
        monetization,
        title,
        message,
        monetizationDisabled,
        backgroundImageUrlValue,
        editPost,
        isPostitLoaded,
    } = useEditImage({ postitId, profileId })

    const setTitle = useEditImagePostStore(store => store.setTitle)
    const setMessage = useEditImagePostStore(store => store.setMessage)
    const setMonetization = useEditImagePostStore(
        store => store.setMonetization,
    )
    const setFile = useEditImagePostStore(store => store.setFile)
    const reset = useEditImagePostStore(store => store.reset)

    useEffect(() => () => reset(), [reset])

    return (
        <Page>
            <NavigationBackButton />

            <Toolbar>
                <p
                    style={{
                        fontSize: vars.font.size.xm,
                        fontWeight: 600,
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    Edit image post
                </p>
            </Toolbar>

            <Content>
                <IonToast
                    isOpen={uploadFailed}
                    duration={3000}
                    className={styles.ionToast}
                    color="danger"
                    message="Oops! Something went wrong. Please try again later."
                />

                <IonToast
                    isOpen={isFileInvalid}
                    duration={3000}
                    className={styles.ionToast}
                    color="danger"
                    message="Oops! The selected image file is not valid. Ensure the file is a proper image format and not empty, then try again."
                />

                <IonToast
                    isOpen={updateComplete}
                    duration={2000}
                    className={styles.ionToast}
                    color="success"
                    message="Post was updated successfully."
                    onDidDismiss={() => {
                        reset()
                        navigate(-1)
                    }}
                />

                {monetization && (
                    <SubscriptionPickerModal
                        isOpen={VisibilityAdt.is.Visible(
                            paymentPickerVisibility,
                        )}
                        text={{
                            actions: {
                                ok: "Okay",
                            },
                            title: "Monetization Options",
                            description:
                                "Choose from the following options to monetize your contribution:",
                            subscription: {
                                free: {
                                    title: "Free for Everyone",
                                    description:
                                        "Your contribution is accessible to all users for free.",
                                },
                                subscriber: {
                                    title: "Free for Subscribers",
                                    description:
                                        "Your contribution is accessible for free only to your channel's subscribers. All other users must pay a price to access it.",
                                },
                                noneSubscriber: {
                                    title: "For Non-Subscribers",
                                    description:
                                        "You can set a price that all users except your subscribers must pay to access your contribution.",
                                },
                            },
                        }}
                        monetization={monetization}
                        onCanceled={() =>
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                        }
                        onConfirmed={monetization => {
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Invisible({}),
                            )
                            setMonetization(monetization)
                        }}
                    />
                )}

                <div className={styles.content}>
                    <div
                        className={classNames(
                            styles.uploadButton,
                            backgroundImageUrlValue ? "" : styles.border,
                        )}
                        style={{
                            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%),
                            url(${backgroundImageUrlValue}) no-repeat center/cover`,
                        }}
                        onClick={() => inputFile.current?.click()}
                    >
                        <div className={styles.iconWrapper}>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={faImages}
                                color="black"
                            />
                        </div>
                    </div>
                    <input
                        ref={inputFile}
                        disabled={!isPostitLoaded}
                        style={{ display: "none" }}
                        type="file"
                        accept="image/png, image/jpeg, image/gif"
                        onChange={async evt => {
                            evt.persist()
                            evt.preventDefault()
                            const files = await fromEvent(evt)
                            pipe(files, A.lookup(0), O.map(setFile))
                        }}
                    />
                    <div
                        className={classNames(
                            styles.inputWrapper,
                            styles.titleWrapper,
                        )}
                    >
                        <IonInput
                            className={styles.title}
                            placeholder="What is your post about"
                            disabled={!isPostitLoaded}
                            value={title}
                            maxlength={MAX_TITLE_LENGTH}
                            clearInput
                            onIonInput={flow(
                                getEventDetailValue,
                                O.map(setTitle),
                            )}
                        />

                        <p
                            className={styles.characters}
                            style={{ fontSize: vars.font.size.xxs }}
                        >
                            {title
                                ? `${title.length} / ${MAX_TITLE_LENGTH}`
                                : ""}
                        </p>
                    </div>
                    <div className={styles.inputWrapper}>
                        <IonTextarea
                            className={styles.description}
                            disabled={!isPostitLoaded}
                            placeholder="Add a description to your image post."
                            value={message}
                            maxlength={MAX_CONTENT_LENGTH}
                            onIonInput={flow(
                                getEventDetailValue,
                                O.map(setMessage),
                            )}
                        />

                        <p
                            className={styles.characters}
                            style={{ fontSize: vars.font.size.xxs }}
                        >
                            {message
                                ? `${message.length} / ${MAX_CONTENT_LENGTH}`
                                : ""}
                        </p>
                    </div>

                    <p
                        style={{
                            fontSize: vars.font.size.s,
                            opacity: "50%",
                            margin: "8px 4px",
                        }}
                    >
                        <span
                            style={{
                                fontSize: vars.font.size.s,
                                fontWeight: 600,
                            }}
                        >
                            Note:
                        </span>{" "}
                        This feature is only active in the test environment and
                        will later only be available for monetized profiles.
                    </p>

                    <button
                        disabled={monetizationDisabled}
                        className={classNames(styles.monetizeWrapper)}
                        onClick={() =>
                            setPaymentPickerVisibility(
                                VisibilityAdt.as.Visible({}),
                            )
                        }
                    >
                        <div className={styles.monetizeTextWrapper}>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={faCircleStar}
                                color="white"
                            />
                            <div style={{ width: "100%" }}>
                                <p
                                    style={{
                                        fontSize: vars.font.size.m,
                                        color: "var(--text-color, white)",
                                        textAlign: "left",
                                    }}
                                >
                                    Edit Monetization Options
                                </p>
                            </div>
                            <FontAwesomeIcon
                                className={styles.navIcon}
                                icon={faAngleRight}
                                color="var(--nav-icon-color, white)"
                            />
                        </div>
                        <div
                            className={classNames(
                                styles.monetizeInfoTextWrapper,
                            )}
                        >
                            {monetization && monetization.type === "None" && (
                                <>
                                    <p
                                        style={{
                                            fontSize: vars.font.size.m,
                                            textAlign: "left",
                                        }}
                                    >
                                        Free for Everyone
                                    </p>
                                </>
                            )}
                            {monetization &&
                                monetization.type === "SubscriptionOnly" && (
                                    <>
                                        <p
                                            style={{
                                                fontSize: vars.font.size.m,
                                                textAlign: "left",
                                            }}
                                        >
                                            Free for Subscribers
                                        </p>
                                        <p
                                            style={{
                                                fontSize: vars.font.size.m,
                                                textAlign: "left",
                                            }}
                                        >
                                            {`For Non-Subscribers ${monetization.amount} ${monetization.currency}`}
                                        </p>
                                    </>
                                )}
                        </div>
                    </button>
                </div>
            </Content>
            <Footer className={classNames("ion-no-border", styles.footer)}>
                <IonButton
                    disabled={isUploadButtonDisabled}
                    className={styles.footerButton}
                    data-test="action-post-text"
                    expand="block"
                    onClick={() => {
                        if (!profileId) return

                        if (!title && !message)
                            return editPost({ body: undefined, id: postitId })
                        else if (title) {
                            editPost({
                                body: {
                                    title,
                                    message,
                                },
                                id: postitId,
                            })
                        }
                    }}
                >
                    Upload
                </IonButton>
            </Footer>
        </Page>
    )
}

export default EditImagePostPage

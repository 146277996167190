import { IonButton, IonContent, IonModal } from "@ionic/react"
import classNames from "classnames"
import { FC } from "react"

import { vars } from "theme/variables.css"
import * as styles from "./title-message-modal.css"

export type TitleMessageModalModel = {
    className?: string
    presentingElement?: HTMLElement | undefined

    title: string
    message: string
    action: string

    isOpen: boolean
    onClosed: () => void
}
//TODO: implement using `Drawer`
export const TitleMessageModal: FC<TitleMessageModalModel> = ({
    className,
    presentingElement,
    title,
    message,
    action,
    isOpen,
    onClosed,
}) => (
    <IonModal
        className={classNames(styles.modal, className)}
        presentingElement={presentingElement}
        canDismiss
        isOpen={isOpen}
        onDidDismiss={onClosed}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        animated
        backdropDismiss
        showBackdrop
    >
        <IonContent fullscreen style={{ padding: "32px 24px" }}>
            <div className={styles.content}>
                <div className={styles.wrapper}>
                    <div style={{ margin: "auto" }}>
                        <p
                            style={{
                                fontSize: vars.font.size.l,
                                fontWeight: 500,
                                color: vars.color.medium.hex,
                            }}
                        >
                            {title}
                        </p>
                    </div>

                    <div style={{ margin: "auto" }}>
                        <p
                            style={{
                                fontSize: vars.font.size.regular,
                                fontWeight: 400,
                                color: vars.color.medium.hex,
                            }}
                        >
                            {message}
                        </p>
                    </div>
                </div>

                <IonButton
                    expand="block"
                    className={styles.closeButton}
                    style={{
                        fontSize: vars.font.size.m,
                        fontWeight: 700,
                    }}
                    onClick={onClosed}
                >
                    {action}
                </IonButton>
            </div>
        </IonContent>
    </IonModal>
)

import classNames from "classnames"
import { FC } from "react"

import { StyleReactProps } from "@ionic/react/dist/types/components/react-component-lib/interfaces"

import { Avatar, createStateBorderStyle } from "../../components/avatars/avatar"
import { AvatarLoading } from "../../components/avatars/avatar-loading"
import { useMyUserProfiles } from "../../hooks/use-my-user-profiles"

import { identity } from "fp-ts/function"
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as styles from "./my-profile-avatar.css"

export type MyProfileAvatarModel = {
    className?: string
    slot?: string | undefined
    avatarSize?: number
    style?: StyleReactProps["style"]
    onUserClicked?: () => void
    isAuthorizedAccount: boolean
}
export const MyProfileAvatar: FC<MyProfileAvatarModel> = ({
    className = "",
    slot,
    style,
    avatarSize,
    onUserClicked,
    isAuthorizedAccount,
}) => {
    const myProfilesQuery = useMyUserProfiles(identity, isAuthorizedAccount)
    const showDefaultAvatar =
        (myProfilesQuery.isSuccess &&
            myProfilesQuery.data.profiles.length <= 0) ||
        !isAuthorizedAccount

    const showRegularAvatar =
        myProfilesQuery.isSuccess && myProfilesQuery.data.profiles.length > 0
    return (
        <div
            slot={slot}
            className={classNames(styles.wrapper, className)}
            onClick={onUserClicked}
        >
            {myProfilesQuery.isLoading ? (
                <AvatarLoading style={style} size={avatarSize} />
            ) : myProfilesQuery.isError ? (
                <Avatar
                    classNameImage={styles.avatar}
                    size={avatarSize}
                    style={style}
                    styleState="None"
                />
            ) : (
                <>
                    {showDefaultAvatar && (
                        <LazyLoadImage
                            width={avatarSize}
                            height={avatarSize}
                            alt="Default profile image"
                            className={styles.avatar}
                            src="/images/default_avatar.jpg"
                            style={{
                                ...createStateBorderStyle("None"),
                                ...style,
                            }}
                        />
                    )}
                    {showRegularAvatar && (
                        <Avatar
                            className={styles.avatar}
                            src={myProfilesQuery.data?.profiles.at(0)?.imageUrl}
                            size={avatarSize}
                            style={style}
                            styleState={
                                myProfilesQuery.data?.profiles.at(0)?.isLive
                                    ? "isLive"
                                    : "None"
                            }
                        />
                    )}
                </>
            )}
        </div>
    )
}

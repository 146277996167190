import classNames from "classnames"
import * as A from "fp-ts/Array"
import * as O from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import { FC, useMemo, useState } from "react"
import { match } from "ts-pattern"

import { mergeSortedInfiniteData } from "../../../../api/api-utils"
import { PostitId, UserProfileId, VideoId } from "../../../../api/branded-types"
import { useRecommendedPostits } from "../../../../hooks/use-recommended-postits"
import { useRecommendedVideos } from "../../../../hooks/use-recommended-videos"
import { fill } from "../../../../utils/array"
import { PostLoadingTile } from "../../../search/videos/post-loading-tile"
import { PostAreaTile, isPostitArea, isVideoArea } from "../post/post-area-tile"
import { VideoAreaTile } from "./video-area-tile"

import { InfiniteScroll } from "common/infinite-scroll"
import { vars } from "theme/variables.css"
import * as styles from "./recommended-post-area-view.css"

export type RecommendedVideosAreaViewModel = {
    className?: string
    title?: string
    placeholder?: string
    profileId?: UserProfileId
    onUserClicked: (id: UserProfileId) => void
    onVideoSelected: (videoId: VideoId) => void
    onPostSelected: (postId: PostitId) => void
}

export const RecommendedPostAreaView: FC<RecommendedVideosAreaViewModel> = ({
    className = "",
    title,
    placeholder,
    profileId,
    onUserClicked,
    onVideoSelected,
    onPostSelected,
}) => {
    const feedRecommendedVideosQuery = useRecommendedVideos(profileId)
    const feedRecommendedPostsQuery = useRecommendedPostits(profileId)

    const feedRecommendedVideosData = useMemo(
        () =>
            feedRecommendedVideosQuery.data?.pages.flatMap(
                page => page.items,
            ) ?? [],
        [feedRecommendedVideosQuery.data?.pages],
    )
    const feedRecommendedPostsData = useMemo(
        () =>
            feedRecommendedPostsQuery.data?.pages.flatMap(page => page.items) ??
            [],
        [feedRecommendedPostsQuery.data?.pages],
    )

    const totalItemsFetched =
        feedRecommendedVideosData.length + feedRecommendedPostsData.length
    const initialDesiredAmount = totalItemsFetched > 0 ? totalItemsFetched : 50
    const [desiredAmount, setDesiredAmount] = useState(initialDesiredAmount)

    const data = useMemo(
        () =>
            mergeSortedInfiniteData({
                firstItems: feedRecommendedVideosData,
                secondItems: feedRecommendedPostsData,
                firstHasNextPage: feedRecommendedVideosQuery.hasNextPage,
                secondHasNextPage: feedRecommendedPostsQuery.hasNextPage,
                desiredAmount,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            desiredAmount,
            feedRecommendedVideosData,
            feedRecommendedPostsData,
            feedRecommendedVideosQuery.hasNextPage,
            feedRecommendedVideosQuery.fetchNextPage,
            feedRecommendedPostsQuery.hasNextPage,
            feedRecommendedPostsQuery.fetchNextPage,
        ],
    )

    const videosState = match(feedRecommendedVideosQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const postsState = match(feedRecommendedPostsQuery)
        .with({ isFetching: true }, () => "Loading" as const)
        .with({ isSuccess: true, isFetching: false }, () => "Loaded" as const)
        .with({ isError: true }, () => "Failed" as const)
        .otherwise(() => "Unloaded" as const)

    const contentState =
        videosState === "Loaded" && postsState === "Loaded"
            ? "Loaded"
            : videosState === "Failed" || postsState === "Failed"
              ? "Failed"
              : videosState === "Loading" || postsState === "Loading"
                ? "Loading"
                : "Unloaded"

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.header}>
                <h1
                    style={{
                        fontSize: vars.font.size.l,
                        color: "white",
                    }}
                >
                    {title}
                </h1>
            </div>
            <InfiniteScroll
                disabled={
                    !feedRecommendedPostsQuery.hasNextPage &&
                    !feedRecommendedVideosQuery.hasNextPage
                }
                state={contentState}
                threshold="100px"
                onLoadRequested={() => {
                    setDesiredAmount(prev => prev + 50)
                    if (feedRecommendedVideosQuery.hasNextPage) {
                        feedRecommendedVideosQuery.fetchNextPage()
                    }

                    if (feedRecommendedPostsQuery.hasNextPage) {
                        feedRecommendedPostsQuery.fetchNextPage()
                    }
                }}
            >
                <div className={styles.content}>
                    {pipe(
                        data,
                        A.map(post =>
                            pipe(
                                post,
                                O.fromPredicate(isVideoArea),
                                O.fold(
                                    () =>
                                        pipe(
                                            post,
                                            O.fromPredicate(isPostitArea),
                                            O.fold(
                                                () => <></>,
                                                postit => (
                                                    <PostAreaTile
                                                        key={postit.post.id}
                                                        area={postit}
                                                        onClicked={() =>
                                                            onPostSelected(
                                                                postit.post.id,
                                                            )
                                                        }
                                                        onUserClicked={() =>
                                                            onUserClicked(
                                                                postit.profile
                                                                    .id,
                                                            )
                                                        }
                                                    />
                                                ),
                                            ),
                                        ),
                                    videoArea => (
                                        <VideoAreaTile
                                            key={videoArea.video.id}
                                            videoArea={{
                                                id: videoArea.video.id,
                                                video: {
                                                    ...videoArea.video,
                                                    paymentRequired:
                                                        videoArea.video
                                                            .monetization
                                                            .type ===
                                                        "SubscriptionOnly",
                                                },
                                                createdAt: videoArea.createdAt,
                                                profile: videoArea.profile,
                                            }}
                                            onUserClicked={() =>
                                                onUserClicked(
                                                    videoArea.video.creatorId,
                                                )
                                            }
                                            onClicked={() =>
                                                onVideoSelected(
                                                    videoArea.video.id,
                                                )
                                            }
                                        />
                                    ),
                                ),
                            ),
                        ),
                    )}

                    {contentState === "Loading" &&
                        !feedRecommendedVideosQuery.isPlaceholderData &&
                        !feedRecommendedPostsQuery.isPlaceholderData &&
                        fill(2, idx => <PostLoadingTile key={idx} />)}
                </div>
            </InfiniteScroll>
            {contentState === "Loaded" && data.length === 0 && (
                <PlaceholderView placeholder={placeholder} />
            )}
        </div>
    )
}

const PlaceholderView: FC<{ placeholder?: string }> = ({ placeholder }) => (
    <div className={styles.infoBox}>
        <p
            style={{
                fontSize: vars.font.size.m,
                color: "white",
            }}
        >
            {placeholder}
        </p>
    </div>
)

import { makeApi } from "@zodios/core"
import z from "zod"

import {
    AgoraVideo,
    ApiError,
    LiveEventEnded,
    PlannedLiveEventResponse,
    Resolution,
} from "../api-models"
import { LiveEventId } from "../branded-types"

export const liveEventCreatorApi = makeApi([
    {
        method: "post",
        path: "/v1/event",
        description: "Creates only a planned live event.",
        alias: "createPlannedLiveEvent",
        parameters: [
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    title: z.string(),
                    start: z.string(),
                }),
            },
        ],
        response: z.object({
            data: PlannedLiveEventResponse,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "put",
        path: "/v1/event/:id/session",
        description: "Initiate a live event with a specific event ID.",
        alias: "startLiveEventById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
            {
                name: "body",
                type: "Body",
                schema: z.object({
                    title: z.string(),
                    resolution: Resolution,
                }),
            },
        ],
        response: z.object({
            data: AgoraVideo,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/event/:id/session",
        description: "Stop an initiated live event.",
        alias: "stopLiveEventById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
        ],
        response: z.object({
            data: LiveEventEnded,
        }),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
    {
        method: "delete",
        path: "/v1/event/:id",
        alias: "deleteLiveEventById",
        parameters: [
            {
                name: "id",
                type: "Path",
                schema: LiveEventId,
            },
        ],
        response: z.object({}),
        errors: [
            {
                status: "default",
                schema: ApiError,
            },
        ],
    },
])
